.card {
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.cardHeader {
  padding: 1rem 1.325rem;
  background: var(--dove-light);
  color: white;
  
  h4 {
    margin: 0;
    color: var(--slate);
  }
}

.cardContent {
  padding: 1rem 1.325rem;
}
