.displayHeading {
  font-size: 3rem;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.displayLink {
  text-decoration: none;
  color: inherit;
}
