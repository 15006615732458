@import '../../sass/_grid';

.dashboardGrid {
  display: grid;
  grid-gap: 1.25rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  padding: 1rem 1.5rem 3rem;

  @media all and (min-width: $desktop) {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, auto);
  }
}
